import React from 'react'
import { Helmet } from "react-helmet";

export default function MetaHome() {
  return (
    <Helmet>
    <title>
      Solutions sur-mesure pour votre site web professionnel - FR Webdesign
    </title>
    <meta name="description" content="Boostez votre présence en ligne avec des solutions web sur-mesure (assistance et maintenance incluses) pour un site web professionnel optimisé SEO." />
    <link rel="canonical" href="https://frwebdesign.net/" />
    <meta
      property="og:title"
      content="Solutions sur-mesure pour votre site web professionnel - FR Webdesign"
    />
    <meta property="og:description" content="Boostez votre présence en ligne avec des solutions web sur-mesure (assistance et maintenance incluses) pour un site web professionnel optimisé SEO." />
    <meta property="og:type" content="siteweb" />
    <meta property="og:site_name" content="FR Webdesign" />
    <meta property="og:url" content="https://frwebdesign.net" />
    <meta property="og:image" content="https://frwebdesign.net/images-site-web/meta/home.jpg" />
    <script type="application/ld+json">{`
      {
        "@context": "https://schema.org/",
        "@type": "Service",
        "logo": "https://frwebdesign.net/LOGO-FR-WEBDESIGN.svg",
        "@id":"https://frwebdesign.net",
        "url":"https://frwebdesign.net",
        "name": "Solutions sur-mesure pour votre site web professionnel - FR Webdesign",
        "description": "Boostez votre présence en ligne avec des solutions web sur-mesure (assistance et maintenance incluses) pour un site web professionnel optimisé SEO."
      }
    `}</script>
  </Helmet>
)
}
